export default { 
    meg:  { 
        logo: 'Shanghai Vsteach Intelligent Technology Co., Ltd.', 
        home: 'Home Page',
        merchants: 'Products of merchants', 
        news: 'News', 
        software: 'Download software', 
        contact: 'Contact us', 
        machine: 'ED-122D intelligent medium-speed paper cup machine', 
        porduceDest: 'The machine is designed with unique appearance and compact structures, and is driven by helical-gear gear motor and sealed with ultrasonic wave on the lateral side. It is equipped with a single plate structure and release cam dividing apparatus so that it characterized by high precision, large loading capacity and stable performance.  ', 
        interaction: 'Click to enter 3D interaction', 
        regard: 'About Vsteach technology team', 
        date: 'Nov. 1, 2022', 
        newTitle: 'Shanghai Vsteach Intelligent Technology Co., Ltd. is dedicated to the research and development and application of virtual simulation, Internet of things, artificial intelligence, big data analysis, cloud computing and other technologies, with wide applications in smart manufacturing and medical services together with other fields as well as cooperative experience......',
        newDetail: 'For more details', 
        softwareSingAccount: 'Register enterprise account number', 
        softwareSingAccountDest: 'The account number will be used in the subsequent software after its setting is completed', 
        softwareSingBtn: 'Register', 
        softwareSingSet: 'Set customer VR visit account number and sale interpretation account number', 
        softwareSingSetDest: 'Only when you complete step1 for enterprise registration can you log in the setting page for account number setting. ', 
        softwareSingSetDestTow: 'Only when your account number is set completely can you log in the software in  step3. ',  
        softwareSingTwo: 'Set account', 
        softwareDownload: 'Download and install software', 
        softwareDownloadDest: 'If you want to experience the immersive visit, you need to download the following two customer terminals: Operating terminal (PC), VR visit terminal (PC). The operating terminal of the interpreter is mainly used for logging into the system by the interpreter to choose the customers for remote reception and smart guidance. VR visit is chiefly employed by visit users to download, and the software is required to be used with VR glasses that will bring the immersive experience for you. ', 
        softwareExplain: 'Operating terminal of the interpreter (win10)', 
        softwareFile: 'File size: 490 M', 
        softwareVr: 'VR visit terminal (win10)', 
        softwareFileTow: 'File size: 520 M', 
        softwareSing: 'Click to Download', 
        email: 'Email', 
        service: 'Customer service hotline: 021-611 55879', 
        city: 'Headquarters Address: Room 507, Block 3, Phase 2, IBP International Business Garden, Changning District, Shanghai', 
        websiteHome: 'Website Homepage',
        phone: 'Tel: 021-611 55879', 
        copyright: 'All rights reserved', 
        internetContentProvider: 'Record No.: H ICP B No. 19043806-1',
        more: 'Pay attention to the official WeChat public account number for more information', 
        company: 'Company address: Room 507, Block 3, Phase 2, IBP International Business Garden, Changning District, Shanghai' ,
        citys:'Room 507, Block 3, Phase 2, IBP International Business Garden, Changning District, Shanghai',
        logos:'Vsteach'
    } 
  } 