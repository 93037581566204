<template>
   <div id="app">
    <!-- <p>{{$t('user.login')}}</p> -->
    <router-view/>
   </div>
</template>
<!-- <script>
 export default {
  data(){
    return {
      screenWidth: null,
    }
  },
  mounted() {
        //获取屏幕尺寸
        this.screenWidth = document.body.clientWidth
        window.onresize = () => {
            console.log('高度');
            console.log();
            //屏幕尺寸变化
            return (() => {
                this.screenWidth = document.body.clientWidth
                console.log(this.);
            })()
        }
    },
 
 watch: {
        screenWidth: function (n) {
            if (n <= 1200) {
                console.log('屏幕宽度小于1200了')
            } else {
                console.log('屏幕宽度没有小于1200')
            }
        }
    }
 }

</script> -->
<style>
*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
 
}
#app{
  width: 100%;
  height: 100%;
}

</style>