export default {
  meg: {
      logo:'上海巅思智能科技有限公司',
      home: '首页',
      merchants:'商家商品',
      news:'新闻资讯',
      software:'下载软件',
      contact:'联系我们',
      machine:'ED-122D智能中速纸杯机',
      porduceDest:'本机器的外观设计独特、结构紧凑，采用斜齿轮减速电机驱动、超声波侧边封合技术、单盘结构、放式凸轮分度装置，精度高、承载大、性能稳定。 ',
      interaction:'点击进入3D交互',
      regard:'关于巅思技术团队',
      date:'2022年11月01日',
      newTitle:'上海巅思智能科技有限公司主要致力于虚拟仿真、物联网、人工智能、大数据分析、云计算等技术的研发与应用，在智能制造、智能医疗等领域有着广泛的应用与合作经验…',
      newDetail:'了解详情',
      softwareSingAccount:'注册企业账号',
      softwareSingAccountDest:'该账号设置后将在后续的软件使用中用到',
      softwareSingBtn:'注册',
      softwareSingSet:'设置顾客VR参观账号和销售讲解账号',
      softwareSingSetDest:'只有完成step1 的企业注册，才可以登陆设置页面进行账号设置。',
      softwareSingSetDestTow:'账号设置完成后才可以登陆step3 中的软件。',  
      softwareSingTwo:'设置账号',
      softwareDownload:'下载并安装软件',
      softwareDownloadDest:'如果您想体验沉浸式参观，需下载以下两个客端：讲解员操作端（PC）、VR参观端（PC）。其中讲解员操作端主要用于讲解员登录系统，选择客户经常远程接待和智能引导；VR参观端主要用于参观用户载，该软件需搭配VR眼镜一起使用，才能有沉浸式体验。',
      softwareExplain:'讲解员操作端（win10）',
      softwareFile:'文件大小：490 M',
      softwareVr:'VR参观端（win10）',
      softwareFileTow:'文件大小：520 M',
      softwareSing:'点击下载',
      email:'电子邮件',
      service:'客服热线：021-611 55879',
      city:'总部地址：上海市长宁区IBP国际商务花园2期3座507',
      websiteHome:'网站首页',
      phone:'联系电话：021-611 55879',
      copyright:'版权所有',
      internetContentProvider:'备案号：沪ICP备19043806号-1',
      more:'更多资讯请关注官方微信公众平台',
      company:'公司地址：上海市长宁区IBP国际商务花园2期3座507',
      citys:'上海市长宁区IBP国际商务花园2期3座507',
      logos:'巅思科技'
  }
}