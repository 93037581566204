import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import 'element-plus/dist/index.css'
import ElementPlus from 'element-plus'
// import './utils/rem'

import  i18n  from './language/index.js'

createApp(App).use(i18n).use(store).use(router).use(ElementPlus).mount('#app')
